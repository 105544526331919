import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from "./App";
import Home from './pages/Home';
import FAQ from './pages/FAQ';
import Book from './pages/Book';
// import Flash from './pages/Flash';
import About from './pages/About';
import Projects from './pages/Projects';

class AppRouter extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Routes>
                    {/*pages*/}
                    <Route path='/' element={<App />} />
                    <Route path='home' element={<Home />} />
                    <Route path='faq' element={<FAQ />} />
                    <Route path='book' element={<Book />} />
                    {/* <Route path='flash' element={<Flash />} /> */}
                    <Route path='about' element={<About />} />
                    <Route path='projects' element={<Projects />} />
                </Routes>
            </BrowserRouter>
        );
    }
}

export default AppRouter;