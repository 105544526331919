import '../css/Projects.css'
import Navbar from '../bars/Navbar';
import Footer from '../bars/Footer';
import blue from '../images/projects/tree-blue.png';
import green from '../images/projects/tree-green.png';
import pink from '../images/projects/tree-pink.png';

function Projects() {

    return (
        <div className='Projects'>
            <Navbar />
            <div className='Projects-body'>
                <div className='Projects-chandelier'>
                    <img className='Projects-img Projects-chandelier-img Projects-hide' src={green} alt='Graphic design, green' />
                    <img className='Projects-img Projects-chandelier-img' src={blue} alt='Graphic design, blue' />
                    <img className='Projects-img Projects-chandelier-img Projects-hide' src={pink} alt='Graphic design, pink' />
                </div>
                <div className='Projects-blurb'>
                    <p>
                        I'm open to taking on commissions of projects that require different mediums! For inquiries, please email me at <b>jankypokertattoo@gmail.com</b> with project details.
                    </p>
                </div>
            </div>
            <Footer />
        </div >
    );
}

export default Projects;
