import '../css/Navbar.css';

function Navbar() {

    return (
        <div className="Navbar">
            <div className="Navbar-wrapper">
                <div className="Navbar-links">
                    <a href='/home'> HOME </a>
                    <a href='/faq'> FAQ </a>
                    <a href='/book'> BOOK </a>
                    <a href='/projects'> PROJECTS </a>
                    <a href='/about'> ABOUT </a>
                </div>
                <div className="Navbar-right">
                    <h1 className='Navbar-header'> JANKYPOKER </h1>
                </div>
            </div>
        </div>
    )

} export default Navbar;
