import '../css/Book.css';
import '../css/Flash.css';
import Navbar from '../bars/Navbar';
import Footer from '../bars/Footer';
import icon from '../images/logos/newtab.png';
import tatt from '../images/tatt.png';

function Book() {

    return (
        <div className='Book'>
            <Navbar />
            <img className='Book-img' src={tatt} alt='tattoo' />
            <div className='Book-body'>
                <div className='Book-link'>
                    <a className='Book-a' href='https://forms.gle/oHv7btr52tPJogej8' rel="noreferrer" target='_blank'>BOOK APRIL 2025 NYC</a>
                    <img className='Book-icon' src={icon} alt='new tab' />
                </div>
                <div className='Flash-link'>
                    <a href='https://docs.google.com/presentation/d/1hq6ne_pEsnWjHliVexOXvbYpLVJyl06cJ8GuZ2d5tcU/edit?usp=sharing' rel="noreferrer" target='_blank'><u>current flash</u></a>
                </div>
                <div className='Flash-link'>
                    <a href='https://docs.google.com/presentation/d/1ar-pVqtjo4LvweRmNGBXeOX34LWIP3jzHyidijlpkoA/edit?usp=sharing' rel="noreferrer" target='_blank'><u>archived flash</u></a>
                </div>
                <br />
                <div className='Book-link'>
                    <b>not in nyc? fill out my</b> <a href='https://forms.gle/K7Jcvo3F4p1Dcp2Q7' rel="noreferrer" target='_blank'>&nbsp;<u>waitlist form</u>&nbsp;</a> <b>to receive a notification if I come to your city!</b>
                </div>
            </div>
            <Footer />
        </div >
    );
}

export default Book;
